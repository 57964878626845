import Logo from "../assets/logo.jpg";
import "./Contact.scss";
import ContactUs from '../assets/Contact.jpg'
import ContactTemplate from '../assets/contact-template.jpg'
import emailjs from 'emailjs-com'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faComments, faUser } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
// import {ContactTemplate} from "../assets/contact-template.jpg"
import { useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const Contact = () => {
  const [selected, setSelected] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    destination: '',
    ocassion: ''
  })
  const mapContainerStyle = {
    height: "100%",
    width: "100%"
  };

  const center = {
    lat: 26.8003801, // Latitude of the location
    lng: 80.9229348 // Longitude of the location
  };
  console.log(formData.name);

  const handleSubmit = (e) => {
    e.preventDefault()

    // send email
    emailjs.send('service_ibqlsbc', 'template_j6tx96g', formData, 'k18fnPOI4qpp9KhV9')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
      })
      .catch((error) => {
        console.log('FAILED...', error);
        alert('Message failed to send.');
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }
  return (

    <div className="contact-us">
      <div className="contact-wrapper d-flex">
        <div className="container d-flex justify-content-center">
          <div className="row align-content-center">
            <div className="col-lg-12">
              <div className="banner-heading">
                <div className="d-inline-block">
                  <h1>Contact Us</h1>
                  <div className="heading-underline">
                    <span></span>
                    <span>
                      <img
                        src={Logo}
                        alt=""
                        className=""
                        style={{
                          marginTop: "-3rem",
                        }}
                      />
                    </span>
                    <span></span>
                  </div>
                </div>
                <h2 className="text-white">
                  Sent Us a Message
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-form">
        <section className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="text-center">
                    {/* <img src={Logo} className="w-50" /> */}
                    <h3 className="custom-heading mt-2">Connect with Us
                    </h3>
                    <h3>Start Planning Your Event
                    </h3>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>

                  <div className="card-body p-3 ">
                    <div className="form-group text-start mt-2">
                      <label>Name<FontAwesomeIcon icon={faUser} /></label>
                      <div className="input-group mt-2">
                        <input
                          type="text"
                          value={formData.name}
                          name="name"
                          className="form-control"
                          placeholder="Your name"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group text-start mt-2">
                      <label>Email <FontAwesomeIcon icon={faEnvelope} /></label>
                      <div className="input-group mb-2 mb-sm-0 mt-2">
                        <input
                          type="email"
                          value={formData.email}
                          name="email"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group text-start mt-2">
                      <label>Contact <FontAwesomeIcon icon={faPhoneVolume} /></label>
                      <div className="input-group mb-2 mb-sm-0 mt-2">
                        <input
                          type="number"
                          name="contact"
                          value={formData.contact}
                          className="form-control"
                          id="inlineFormInputGroupUsername"
                          onChange={handleChange}
                          placeholder=""
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group text-start mt-2">
                      <label>Destination<FontAwesomeIcon icon={faLocationDot} />
                      </label>
                      <div className="input-group mb-3 mb-sm-0 mt-2">
                        <input
                          type="text"
                          value={formData.destination}
                          className="form-control"
                          name="destination"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="text-start mt-2">
                      <label>Explore the Occasion <FontAwesomeIcon icon={faComments} />
                      </label>
                      <textarea
                        className="form-control"
                        rows="3"
                        value={formData.ocassion}
                        name="ocassion"
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="text-center">
                      <input
                        type="submit"
                        name="submit"
                        value="submit"
                        className="btn btn-primary rounded-0 py-2 w-100 mt-3"
                      />
                    </div>
                  </div>
                </form>


              </div>
            </div>
            <div className="col-md-6">
              <div className="img-contact">
                <img src={ContactTemplate} />
              </div>
            </div>
            {/* <div className="col-md-6">
              <LoadScript
                googleMapsApiKey="AIzaSyCyifYjSsw2haxR2ujqN7XOI034S_6IlPE" // Replace with your Google Maps API Key
              >
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={12}
                >

                  <Marker

                    position={center}
                    onClick={() => setSelected({position:center, title: 'Anil Caterers'})}
                  />
                  {selected && (
                    <InfoWindow
                      position={center}
                      onCloseClick={() => setSelected(null)}
                    >
                      <div>
                        <h2>Anil Caterers</h2>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </div> */}

          </div>
        </section>
      </div>

    </div>


  );
};

export default Contact;
